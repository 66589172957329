export function validateUriForm(input: string) {
    if (typeof input !== "string") {
        return "Links must have at least 3 letters.";
    }
    if (input.length < 3) {
        return "Links must have at least 3 characters.";
    }
    if (input.length > 50) {
        return "Links must be less than 50 characters.";
    }
    if (!input.match(/[\w\d\-_]+/i)) {
        return "Links can only include letters, numbers, dashes and underscores.";
    }
    return false;
}

export function validateTitleForm(title: string) {
    let error = "";

    if (typeof title !== 'string') { 
        error = "Your game title must have at least 3 letters.";
    } else if (title.length < 3) {
        error = "Your game title must have at least 3 letters.";
    } else if (title.length >= 20) {
        error = "Your game title must have less than 20 letters.";
    } else if (title.toLocaleLowerCase() === "wordle") {
        error = "This title is already taken. By Wordle."
    } else if (title.toLocaleLowerCase() === "yourdle") {
        error = "This title is already taken."
    }

    return error;
}

export function validateWordListForm(wordListRaw: string) {
    if (typeof wordListRaw !== 'string') {
        return "You need at least one word.";
    }

    const wordList = wordListRaw.split('\n')
        .map(w => w.toLocaleLowerCase().trim().replace(/[\s\t]+/g, ' '))
        .filter(w => w.length !== 0);
    
    if (typeof wordList?.[0]?.length !== 'number') { 
        return "You need at least one word.";
    } else {
        for (let i = 0; i < wordList.length; i++) {
            if (wordList[i].length < 3) {
                return "The word '" + wordList[i] + "' is too short. Words can have between 3 and 7 letters.";
            } else if (wordList[i].length > 7) {
                return "The word '" + wordList[i] + "' is too long. Words can have between 3 and 7 letters.";
            }
        }

        for (const word of wordList) {
            if (!word.match(/\w+/)) {
                return "Words can only contain letters.";
            }
        }

        if (wordList.length < 1) {
            return "You need at least one word.";
        } else if (wordList.length >= 50000) {
            return "The maximum number of words is 50,000.";
        }
    }

    return false;
}