import { useAuth0 } from "@auth0/auth0-react";
import { useQuery } from "react-query";

export function getApiUrl() {
    if (process.env.NODE_ENV === "production") {
        return "/api";
    } else if (window.location.hostname.includes(".gitpod.io")) {
        return "/api";
        // return "https://" + window.location.hostname.replace("3000", "1234") + "/api";
    } else {
        return "/unknown-api-endpoint";
    }
}

export class ApiClient {
    public userID: string|undefined;;
    public token: string|undefined;;
    public email: string|undefined;

    async get(endpoint: string, params: Record<string, any> = {}) {
        const query = Object.keys(params)
            .map(key => ({
                key: encodeURIComponent(key),
                value: encodeURIComponent(params[key]),
            }))
            .map(item => item.key + "=" + item.value)
            .join("&");
        const response = await fetch(getApiUrl() + endpoint + "?" + query, {
            method: "GET",
            // @ts-ignore
            "Accept": "application/json",
        });
        return await response.json();
    }

    async post(endpoint: string, params: Record<string, any> = {}, json = false) {
        if (!this.token) {
            throw new Error("Not authenticated");
        }
        const response = await fetch(getApiUrl() + endpoint, {
            method: "POST",
            body: JSON.stringify({
                ...params,
                userID: this.userID,
                email: this.email,
                token: this.token,
            }),
            headers: { 'Content-Type': 'application/json' },
            // @ts-ignore
            "Accept": "application/json",
        });
        if (response.status === 200) {
            return json ? await response.json() : true;
        } else {
            throw new Error("Invalid response");
        }
    }

    public async getGames() {
        return await this.post("/games", {}, true);
    }

    public async getGameInfo(id: string) {
        return await this.get("/game", { id })
    }

    public async setGameTitle(id: string, title: string) {
        return await this.post("/set-game-title", { id, title });
    }

    public async setGameURI(id: string, uri: string) {
        return await this.post("/set-game-uri", { id, uri });
    }

    public async setWordList(id: string, wordList: string[]) {
        return await this.post("/set-word-list", { id, wordList });
    }
}

let client = new ApiClient();

export function useApiClient(): [ApiClient, boolean] {
    const { user, getAccessTokenSilently } = useAuth0();

    useQuery(["token"], async () => {
        client.token = await getAccessTokenSilently({
            audience: 'https://www.yourdle.net',
            scope: 'update:games email',
        });
        client.email = user?.email;
        client.userID = user?.sub;
        return client.token;
    }, { enabled: !!user?.sub });

    return [client, !!client.token];
}