import { useAuth0 } from '@auth0/auth0-react';
import { Menu } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { Link, Route, Routes } from "react-router-dom";
import styles from './app.module.scss';
import CreateFlow from './create-flow';
import ProfilePage from './profile';

export default function App(props: any) {
    const {
      isLoading,
    //   error,
      logout,
      user,
      isAuthenticated,
      loginWithRedirect,
    //   getAccessTokenSilently,
    } = useAuth0();

    const wide = useMediaQuery('(min-width: 50em)');

    const authHeader = isLoading ? null : (<div className={styles.authHeader}>
        {isAuthenticated && (<>
            <Link to="/profile">Your Games</Link>
            <p>{wide && "Signed in as "}{user?.name}</p>
            <Menu>
                <Menu.Label>Yourdle Account</Menu.Label>
                <Menu.Item onClick={() => logout({ returnTo: window.location.origin })}>
                    Sign out
                </Menu.Item>
            </Menu>
        </>)}
        {!isAuthenticated && (
            <Menu>
                <Menu.Label>Yourdle Account</Menu.Label>
                <Menu.Item onClick={() => loginWithRedirect({ returnTo: window.location.origin })}>Sign in</Menu.Item>
            </Menu>
        )}
    </div>);

    return <div className={styles.container}>
        <header>
            <h1><Link to="/">Yourdle</Link></h1>
            {authHeader}
        </header>
        <main>
            <Routes>
            <Route path="/profile" element={<ProfilePage />} />
                <Route path="/*" element={(
                    <CreateFlow loggedIn={isAuthenticated} login={loginWithRedirect} />
                )} />
            </Routes>
        </main>
        <footer>
            {/* <a href="#">Terms of Use</a> */}
            {/* <a href="#">Privacy Policy</a> */}
            <span><a href="https://www.yourdle.net">Yourdle</a> is not affiliated with <a href="https://nytimes.com/wordle">Wordle</a>.</span>
        </footer>
    </div>
}