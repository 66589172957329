import { useAuth0 } from '@auth0/auth0-react';
import { Button, TextInput, Textarea, Modal } from '@mantine/core';
import { useCallback, useEffect, useState } from 'react';
import { Route, Routes, useNavigate } from "react-router-dom";
import styles from './create-flow.module.scss';
import { getRedirectUrl } from './utils';
import { useDebouncedValue } from '@mantine/hooks';
import { validateTitleForm, validateWordListForm } from './form-validation';
import { getApiUrl } from './api';
import { queryClient } from '.';

// function openGame(id: string) {
    // window.location.href = getGameUrl(id);
// }

async function create(title: string, 
                      wordList: string[],
                      userID: string,
                      email: string,
                      token: string) {
    try {
        const response = await fetch(getApiUrl() + "/create", {
            method: "post",
            body: JSON.stringify({ 
                title, 
                wordList, 
                userID, 
                email, 
                token, 
            }),
            headers: { 'Content-Type': 'application/json' },
            // @ts-ignore
            "Accept": "application/json",
        });
        if (response.status === 200) {
            return await response.text();
        }
    } catch (e) {
        console.log(e);
    }
    return null;
}




// function cleanInput(input: string) {
//     return input.trim().replace(/[ \t]+/g, ' ');
// }

// function validateTitle(title: string) {
//     let error = "";

//     if (title.length < 3) {
//         error = "Your game title must have at least 3 letters.";
//     } else if (title.length >= 20) {
//         error = "Your game title must have less than 20 letters.";
//     } else if (title.toLocaleLowerCase() === "wordle") {
//         error = "This title is already taken. By Wordle."
//     } else if (title.toLocaleLowerCase() === "yourdle") {
//         error = "This title is already taken."
//     }

//     return error;
// };

// function validateWordList(wordListRaw: string) {
//     const wordList = wordListRaw.split('\n')
//         .map(w => w.toLocaleLowerCase().trim().replace(/[\s\t]+/g, ' '))
//         .filter(w => w.length !== 0);

//     let error = "";

//     // let allSameLength = true;
//     for (let i = 0; i < wordList.length; i++) {
//         if (wordList[i].length !== 5) {
//             error = `The word "${wordList[i]}" is not 5 letters long.`;
//             break;
//         }
//     }

//     let allMatchingPattern = true;
//     for (const word of wordList) {
//         if (!word.match(/\w+/)) {
//             allMatchingPattern = false;
//             break;
//         }
//     }

//     if (wordList.length < 1) {
//         error = "Your word list must have at least 1 word.";
//     } else if (wordList.length >= 50000) {
//         error = "Your word list must have less than 50,000 words.";
//     } else if (!allMatchingPattern) {
//         error = "Game words can only contain letters."
//     }

//     return error;
// }

export function Welcome(props: any) {
    const [error, setError] = useState("");

    const validate = useCallback(() => {
        setError(validateTitleForm(props.title) || "");
    }, [props.title, setError]);

    const submit = useCallback(event => {
        event.preventDefault();
        event.stopPropagation();
        const e = validateTitleForm(props.title);
        setError(e || "");
        if (!e) {
            props.next();
        }
    }, [props, setError]);

    return <div className={styles.welcome}>
        <h2>Create your own word games inspired by Wordle</h2>
        <form onSubmit={submit}>
            <TextInput size="lg"
                value={props.title}
                error={error}
                onFocus={() => setError("")}
                onBlur={e => {
                    if (e.target.value.trim().length) {
                        validate();
                    }
                }}
                onChange={e => props.setTitle(e.target.value)}
                placeholder="Enter a name for your game..."
                label="To begin, choose a name for your game. You can always change the name later - it's Yourdle." />
            <Button size="lg" onClick={submit}>Get Started</Button>
        </form>
    </div>;
}

export function WordListComponent(props: any) {
    const [error, setError] = useState("");

    const validate = useCallback(() => {
        setError(validateWordListForm(props.wordList) || "");
    }, [props.wordList, setError]);

    const submit = useCallback(event => {
        event.preventDefault();
        event.stopPropagation();
        const e = validateWordListForm(props.wordList);
        setError(e || "");
        if (!e) {
            props.next();
        }
    }, [props, setError]);

    return <div className={styles.wordList}>
        <p>Creating New Game: {props.title}</p>
        <h2>Choose words for your game</h2>
        <form onSubmit={submit}>
            <Textarea size="lg"
                value={props.wordList}
                error={error}
                onFocus={() => setError("")}
                onBlur={e => {
                    if (e.target.value.trim().length) {
                        validate();
                    }
                }}
                onChange={e => props.setWordList(e.target.value)}
                minRows={8}
                disabled={props.submitting}
                placeholder="Type or paste words to include in your game."
                label="Each word in your game must have 5 letters. Enter one word per line." />
            <Button size="lg" onClick={submit} loading={props.submitting}>
                {!props.submitting ? "Publish Game" : "Publishing Game ..."}
            </Button>
            {props.submissionError && <p>Something went wrong &mdash; try again in a few minutes.</p>}
        </form>
    </div>;
}

export default function CreateFlow(props: any) {
    const navigate = useNavigate();
    const { user, getAccessTokenSilently } = useAuth0();

    const [title, setTitle] = useState("");
    const [wordList, setWordList] = useState("");

    const [debouncedTitle] = useDebouncedValue(title, 1000);
    const [debouncedWordList] = useDebouncedValue(wordList, 1000);

    const [signInModalOpen, setSignInModalOpen] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [submissionError, setSubmissionError] = useState(false);

    useEffect(() => {
        const storedTitle = window.localStorage.getItem("yourdle-title");
        const storedWordList = window.localStorage.getItem("yourdle-word-list");
        console.log("Stored title: ", storedTitle);
        console.log("Stored wordList: ", storedTitle);
        if (typeof storedTitle === 'string' && !title) {
            setTitle(storedTitle);
        }
        if (typeof storedWordList === 'string' && !wordList) {
            setWordList(storedWordList);
        }
    }, [title, wordList]);

    useEffect(() => {
        console.log("Updated title: ", debouncedTitle);
        console.log("Updated wordList: ", debouncedWordList);
        window.localStorage.setItem("yourdle-title", debouncedTitle);
        window.localStorage.setItem("yourdle-word-list", debouncedWordList);
    }, [debouncedTitle, debouncedWordList]);

    // useEffect(() => {
    //     if (!title && window.location.pathname.includes("word-list")) {
    //         navigate("/");
    //     }
    // }, [title, navigate]);

    return <div className={styles.container}>
        <Routes>
            <Route path="/" element={(
                <Welcome title={title}
                    setTitle={setTitle}
                    next={() => navigate("/create/word-list")} />
            )} />
            <Route path="/create/word-list" element={(
                <WordListComponent title={title}
                    wordList={wordList}
                    setWordList={setWordList}
                    submitting={submitting}
                    submissionError={submissionError}
                    next={async () => {
                        if (validateTitleForm(title)) {
                            navigate("/");
                            return;
                        }
                        if (!props.loggedIn) {
                            setSignInModalOpen(true);
                            return;
                        }

                        setSubmitting(true);
                        setSubmissionError(false);

                        const wordList2 = wordList.split('\n')
                            .map(w => w.toLocaleLowerCase().trim().replace(/[\s\t]+/g, ' '))
                            .filter(w => w.length !== 0);

                        try {
                            const token = await getAccessTokenSilently({
                                audience: 'https://www.yourdle.net',
                                scope: 'update:games email',
                            });

                            if (!user?.sub || !user?.email) {
                                setSubmissionError(true);
                                return;
                            }

                            const response = await create(title, 
                                wordList2, 
                                user.sub, 
                                user.email, 
                                token);

                            if (response) {
                                window.localStorage.removeItem("yourdle-title");
                                window.localStorage.removeItem("yourdle-word-list");
                                queryClient.invalidateQueries(["games"]);
                                navigate("/profile");
                                // openGame(response);
                            } else {
                                setSubmissionError(true);
                                setSubmitting(false);
                            }
                        } catch (e) {
                            setSubmissionError(true);
                            setSubmitting(false);
                            return;
                        }
                    }} />
            )} />
        </Routes>
        <Modal opened={signInModalOpen}
            title="Sign in"
            closeOnClickOutside={false}
            onClose={() => setSignInModalOpen(false)}>
            <div className={styles.signInModal}>
                <h2>Your game is ready to publish</h2>
                <p>Sign up for a free Yourdle account to publish {props.title || "your game"}.</p>
                <p>An account allows you to update {props.title || "your game"} with new words and other changes after it's published.</p>
                <Button variant="filled" onClick={() => props.login({
                    connection: "google-oauth2",
                    returnTo: getRedirectUrl(),
                })}>
                    Continue with Google
                </Button>
                <Button variant="filled" onClick={() => props.login({
                    connection: "twitter",
                    returnTo: getRedirectUrl(),
                })}>
                    Continue with Twitter
                </Button>
                <Button variant="subtle" onClick={() => props.login({
                    returnTo: getRedirectUrl(),
                })}>
                    Or sign in with a username and password
                </Button>
            </div>
        </Modal>
    </div>
}