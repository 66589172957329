import React from 'react';
import ReactDOM from 'react-dom';
import { Auth0Provider } from '@auth0/auth0-react';
import { ReactQueryDevtools } from "react-query/devtools";
import { QueryClient, QueryClientProvider } from "react-query"; import './index.scss';
import { NotificationsProvider } from '@mantine/notifications';
import App from './App2';
import { BrowserRouter } from 'react-router-dom';

export const queryClient = new QueryClient();

const dev = process.env.NODE_ENV !== 'production';

if (!dev) {
  if (window.location.protocol !== 'https:' || window.location.hostname !== "www.yourdle.net") {
    window.location.href = "https://www.yourdle.net" + window.location.pathname;
  }
}

ReactDOM.render(
  <React.StrictMode>
    <Auth0Provider
      domain="yourdle.us.auth0.com"
      clientId="UZD21JSDsshLL3ZE5SaRCJeLWweU0VsX"
      cacheLocation="localstorage"
      redirectUri={window.location.origin}>
      <NotificationsProvider position="top-center" limit={2}>
        <QueryClientProvider client={queryClient}>
          <BrowserRouter>
            <App />
          </BrowserRouter>
          {dev && <ReactQueryDevtools initialIsOpen={false} />}
        </QueryClientProvider>
      </NotificationsProvider>
    </Auth0Provider>
  </React.StrictMode>,
  document.getElementById('root')
);
