import { Button, Card, LoadingOverlay, Modal, Textarea, TextInput, Tooltip } from '@mantine/core';
import { useClipboard } from '@mantine/hooks';
import { useNotifications } from '@mantine/notifications';
import { useCallback, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { queryClient } from '..';
import { validateUriForm, validateWordListForm, validateTitleForm } from '../form-validation';
import { useApiClient } from '../api';
import styles from './game.module.scss';

function getShareType() {
    const clipboardSupported = typeof window.navigator.clipboard?.writeText !== 'undefined';
    const shareSupported = typeof window.navigator.canShare !== "undefined";
    if (clipboardSupported) {
        return "clipboard";
    }
    if (shareSupported) {
        return "share";
    }
    return "none";
}

export interface GameListingProps {
    game: any,
}

export default function GameListing(props: GameListingProps) {
    const notifications = useNotifications();
    const clipboard = useClipboard();
    const [api] = useApiClient();
    const [modal, setModal] = useState<string | null>(null);
    const [uriOverride, setUriOverride] = useState(props.game.uri ?? "");
    const [titleOverride, setTitleOverride] = useState<string | undefined>(undefined);
    const [currentWordListOverride, setCurrentWordListOverride] = useState<string | undefined>(undefined);
    const [futureWordListOverride, setFutureWordListOverride] = useState<string | undefined>(undefined);
    const [uriNotAvailable, setUriNotAvailable] = useState(false);
    const [modalError, setModalError] = useState("");

    const game = props.game;

    // @ts-ignore
    const { data: currentGameInfo } = useQuery(["game", props.game.id],
        () => api.getGameInfo(props.game.id),
        { enabled: modal === "word-list" });

    const notify = useCallback((message: string, title = game.title) => {
        notifications.showNotification({
            title,
            message,
            id: game.id,
            autoClose: 5000,
        });
    }, [notifications, game]);

    const uriMutation = useMutation((uri: string) => api.setGameURI(props.game.id, uri), {
        onError: () => {
            setUriNotAvailable(true);
        },
        onSuccess: () => {
            queryClient.invalidateQueries(["games"]);
            setModal(null);
            setUriNotAvailable(false);
            notify("Your custom URL has been saved!");
        },
    });

    const titleMutation = useMutation((value: string) => api.setGameTitle(props.game.id, value), {
        onSuccess: () => {
            queryClient.invalidateQueries(["games"]);
            setModal(null);
            notify("The new title has been saved!", titleOverride);
        },
    });

    const wordListMutation = useMutation(async (value: string[]) => api.setWordList(props.game.id, value), {
        onSuccess: () => {
            queryClient.invalidateQueries(["games"]);
            setModal(null);
            notify("The word list has been updated!");
        },
    });

    const url = "https://play.yourdle.net/"
        + (uriMutation.isSuccess ? uriOverride : (game.uri || game.id))
            .toLowerCase();

    const onCopyLinkClick = useCallback(() => {
        const shareType = getShareType();
        if (shareType === "clipboard") {
            clipboard.copy(url);
            notify("Copied game link to your clipboard!");
        } else if (shareType === "share") {
            window.navigator.share({
                title: game.title,
                url,
            });
        }
    }, [notify, clipboard, url, game]);

    const getCurrentWordListSorted = () => {
        if (!currentGameInfo?.words) {
            return { future: "", current: "" };
        }
        const shift = currentGameInfo.shift ?? 0;
        const index = Math.floor((Date.now() - currentGameInfo.epoch) / 86400000) - shift;
        const sliced = [
            ...currentGameInfo.words.slice(index),
            ...currentGameInfo.words.slice(0, index),
        ];
        return {
            current: sliced.slice(0, 2).join("\n"),
            future: sliced.slice(2).join("\n"),
            shift: index,
        }
    };

    const shareType = getShareType();

    return <Card className={styles.container}>
        <h3>
            <a href={url} target="_blank" rel="noreferrer">{game.title}</a>
            <Tooltip label="Edit Title" withArrow>
                <Button variant="subtle" compact color="dark" onClick={() => {
                    titleMutation.reset();
                    setModalError("");
                    setModal("title");
                }}>
                    <i className="fa fa-pencil" />
                </Button>
            </Tooltip>
        </h3>
        <div className={styles.link}>
            <input type="text" value={url} disabled />
            {shareType !== 'none' && <Tooltip label="Copy Game Link" withArrow>
                <Button size="md" variant="light" onClick={onCopyLinkClick}>
                    {shareType === 'clipboard' && <i className="fa fa-copy" />}
                    {shareType === 'share' && <i className="fa fa-share" />}
                </Button>
            </Tooltip>}
        </div>
        <div className={styles.buttons}>
            <Button fullWidth variant="light" leftIcon={<i className="fa fa-link" />} onClick={() => {
                uriMutation.reset();
                setModalError("");
                setModal("link");
            }}>
                Customize Link
            </Button>
            <Button fullWidth variant="light" leftIcon={<i className="fa fa-list-ul" />} onClick={() => {
                wordListMutation.reset();
                setModalError("");
                setModal("word-list");
            }}>
                Edit Word List
            </Button>
        </div>
        <Modal opened={modal === 'title'}
            title={"Rename Game"}
            closeOnClickOutside={true}
            onClose={() => setModal(null)}>
            <TextInput value={titleOverride ?? game.title}
                onChange={e => setTitleOverride(e.target.value)}
                error={modalError} />
            <Button fullWidth
                loading={titleMutation.isLoading}
                style={{ marginTop: '1rem' }}
                onClick={() => {
                    if (typeof titleOverride !== 'string') {
                        setModal(null);
                        return;
                    }
                    const error = validateTitleForm(titleOverride);
                    if (error) {
                        setModalError(error);
                        return;
                    }
                    titleMutation.mutate(titleOverride ?? "");
                }}>
                Save Changes
            </Button>
        </Modal>
        <Modal opened={modal === 'link'}
            title={"Customize Link"}
            closeOnClickOutside={true}
            onClose={() => setModal(null)}>
            <div className={styles.uriField}>
                <span style={{ whiteSpace: 'nowrap' }}>https://play.wordle.net/</span>
                <TextInput value={uriOverride}
                    onChange={e => {
                        setUriOverride(e.target.value);
                        setUriNotAvailable(false);
                    }}
                    error={modalError}
                    placeholder={game.uri || "custom-link-text"} />
            </div>
            {uriNotAvailable && <p className={styles.uriNotAvailable}>
                The custom link you have entered is already in use by another game.
            </p>}
            <Button fullWidth
                loading={uriMutation.isLoading}
                style={{ marginTop: '1rem' }}
                onClick={() => {
                    if (typeof uriOverride !== 'string') {
                        setModal(null);
                        return;
                    }
                    const error = validateUriForm(uriOverride);
                    if (error) {
                        setModalError(error);
                        return;
                    }
                    uriMutation.mutate(uriOverride);
                }}>
                Save Changes
            </Button>
        </Modal>
        <Modal opened={modal === 'word-list'}
            title={game.title + " Word List"}
            closeOnClickOutside={true}
            onClose={() => setModal(null)}>
            <div style={{ position: 'relative' }}>
                <LoadingOverlay visible={!currentGameInfo} />
                <Textarea 
                    label="Current Words"
                    description="The words below are currently live for users depending on their timezone. Changing them may create confusion for users."
                    minRows={5} 
                    error={modalError}
                    onChange={e => setCurrentWordListOverride(e.target.value)}
                    value={currentWordListOverride ?? getCurrentWordListSorted().current ?? ""}
                />
                <Textarea minRows={5} error={modalError}
                    style={{ marginTop: '1rem' }}
                    label="Future Words"
                    description="The words below will be used in the order they are listed, one per day."
                    onChange={e => setFutureWordListOverride(e.target.value)}
                    value={futureWordListOverride ?? getCurrentWordListSorted().future ?? ""}
                />
            </div>
            <Button fullWidth
                loading={wordListMutation.isLoading}
                style={{ marginTop: '1rem' }}
                onClick={() => {
                    if (typeof currentWordListOverride !== 'string' && typeof futureWordListOverride !== 'string') {
                        // no change
                        setModal(null);
                        return;
                    }
                    const sorted = getCurrentWordListSorted();
                    const combined = [
                        currentWordListOverride ?? sorted.current,
                        futureWordListOverride ?? sorted.future,
                    ].join("\n");
                    const error = validateWordListForm(combined);
                    if (error) {
                        setModalError(error);
                        return;
                    };
                    
                    const wordList = combined.split('\n')
                        .map(w => w.toLocaleLowerCase().trim().replace(/[\s\t]+/g, ' '))
                        .filter(w => w.length !== 0);
                    
                    wordListMutation.mutate(wordList);
                }}>
                Save Changes
            </Button>
        </Modal>
    </Card>;
}