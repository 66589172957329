import { withAuthenticationRequired } from '@auth0/auth0-react';
import { Grid } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { useQuery } from 'react-query';
import { useApiClient } from '../api';
import GameListing from './game-listing';

import styles from "./profile.module.scss";

export function ProfilePage(props: any) {
    // const [loading, setLoading] = useState(false);
    const wide = useMediaQuery('(min-width: 50em)');
    
    const [apiClient, apiReady] = useApiClient();

    const { data } = useQuery(["games"], () => apiClient.getGames(),
        { enabled: apiReady });

    return <div className={styles.container}>
        <h2>Your Games</h2>
        {!data && (
            <svg style={{ margin: 'auto', background: 'none', display: 'block', shapeRendering: 'auto'}} width="200px" height="200px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                <rect x="17.5" y="42.5" width="15" height="15" fill="#d6d6d6">
                    <animate attributeName="y" repeatCount="indefinite" dur="1s" calcMode="spline" keyTimes="0;0.5;1" values="38;42.5;42.5" keySplines="0 0.5 0.5 1;0 0.5 0.5 1" begin="-0.2s"></animate>
                    <animate attributeName="height" repeatCount="indefinite" dur="1s" calcMode="spline" keyTimes="0;0.5;1" values="24;15;15" keySplines="0 0.5 0.5 1;0 0.5 0.5 1" begin="-0.2s"></animate>
                </rect>
                <rect x="42.5" y="42.5" width="15" height="15" fill="#edd041">
                    <animate attributeName="y" repeatCount="indefinite" dur="1s" calcMode="spline" keyTimes="0;0.5;1" values="39.125;42.5;42.5" keySplines="0 0.5 0.5 1;0 0.5 0.5 1" begin="-0.1s"></animate>
                    <animate attributeName="height" repeatCount="indefinite" dur="1s" calcMode="spline" keyTimes="0;0.5;1" values="21.750000000000004;15;15" keySplines="0 0.5 0.5 1;0 0.5 0.5 1" begin="-0.1s"></animate>
                </rect>
                <rect x="67.5" y="42.5" width="15" height="15" fill="#60aa4f">
                    <animate attributeName="y" repeatCount="indefinite" dur="1s" calcMode="spline" keyTimes="0;0.5;1" values="39.125;42.5;42.5" keySplines="0 0.5 0.5 1;0 0.5 0.5 1"></animate>
                    <animate attributeName="height" repeatCount="indefinite" dur="1s" calcMode="spline" keyTimes="0;0.5;1" values="21.750000000000004;15;15" keySplines="0 0.5 0.5 1;0 0.5 0.5 1"></animate>
                </rect>
            </svg>
        )}
        <Grid>
            {data?.map((game: any) => (
                <Grid.Col span={wide ? 6 : 12}>
                    <GameListing key={game.id} game={game} />
                </Grid.Col>
            ))}
        </Grid>
    </div>;
}

export default withAuthenticationRequired(ProfilePage, {
    onRedirecting: () => (<div>Redirecting you to the login page...</div>)
});